const getLangs = () => {
    const langs =[
        {
          id:11,
          code:'ar',
          name:'العربية',
          conttryCode:'ar'
        },
        {
          id:22,
          code:'en',
          name:'English',
          conttryCode:'gb'
        },
       // {
       //   id:33,
      //    code:'ur',
      //    name:'urdu',
      //    conttryCode:'ur'
      //  }
      ];
    return langs;
  };
  export default getLangs;
  